import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { createUseStyles } from "react-jss"
import Cta from "./cta"

const useStyles = createUseStyles({
  bigSection: {
    height: `100%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  overText: {
    fontSize: `calc((4.2 - 1) * 1.2vmax + 1rem)`,
    color: `white`,
    fontWeight: `normal`,
    whiteSpace: `pre-line`,
  },
  imageInset: {
    padding: `0 4vw`,
    textAlign: `center`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
  },
})

interface Props {
  data: GatsbyTypes.MainImageFragment
}

const MainImage: React.FC<Props> = ({ data }: Props) => {
  const classes = useStyles()

  return (
    <div
      style={{
        position: `relative`,
        height: `100%`,
        width: `100%`,
      }}
    >
      {data.contentfulHomeHeader?.image?.gatsbyImageData != null && (
        <GatsbyImage
          image={data.contentfulHomeHeader.image.gatsbyImageData}
          style={{
            height: "100%",
            width: "100%",
            filter: `brightness(0.7) saturate(1.5)`,
          }}
          alt="A view of the mountains from Second Breakfast"
        />
      )}
      <div
        style={{
          position: `absolute`,
          height: `100%`,
          width: `100%`,
          top: 0,
          left: 0,
        }}
      >
        <section className={classes.bigSection}>
          <div className={classes.imageInset}>
            <h1 className={classes.overText}>
              {data.contentfulHomeHeader?.mainText?.mainText}
            </h1>
            <Cta isLight />
          </div>
        </section>
      </div>
    </div>
  )
}

export default MainImage

export const query = graphql`
  fragment MainImage on Query {
    contentfulHomeHeader(title: { eq: "Home Header" }) {
      mainText {
        mainText
      }
      image {
        gatsbyImageData(width: 1200, quality: 100)
      }
    }
  }
`
