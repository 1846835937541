import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import HomeImage from "../components/HomeImage"
import SEO from "../components/seo"
import ColumnItem from "../components/columnitem"
import HomeQuote from "src/components/HomeQuote"
import HomeCta from "src/components/HomeCta"

const useStyles = createUseStyles({
  main: {
    marginTop: props => `${props.height}px`,
    height: props => `calc(100vh - ${props.height}px)`,
    padding: `0 4vw 4vw`,
    boxSizing: "border-box",
  },
  bigSection: {
    height: `100%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  overText: {
    fontSize: `calc((4.2 - 1) * 1.2vmax + 1rem)`,
    color: `white`,
    fontWeight: `normal`,
  },
  imageInset: {
    padding: `0 4vw`,
    textAlign: `center`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  column: {
    width: `50%`,
  },
  midSection: {
    minHeight: `33vh`,
    padding: `4vw`,
    boxSizing: "border-box",
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  columnSection: {
    padding: `0 calc(4vw - 17px) 4vw`,
    margin: `0 auto`,
    boxSizing: "border-box",
    display: `flex`,
  },
  "@media (max-width: 850px)": {
    column: {
      width: `auto`,
    },
    columnSection: {
      flexDirection: `column`,
    },
  },
})

interface Props {
  data: GatsbyTypes.IndexQuery
}

const IndexPage = ({ data }: Props) => {
  const [height, setHeight] = useState<number>(0)
  const classes = useStyles({ height })

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title="Home" />
      <main className={classes.main}>
        <HomeImage data={data} />
      </main>
      <section className={classes.midSection}>
        <HomeQuote data={data} />
      </section>
      <section className={classes.columnSection}>
        <div className={classes.column}>
          {data.leftColumn != null && <ColumnItem data={data.leftColumn} />}
        </div>
        <div className={classes.column}>
          {data.rightColumn != null && <ColumnItem data={data.rightColumn} />}
        </div>
      </section>
      <section
        style={{
          height: `70vh`,
          padding: `0 4vw 4vw`,
        }}
      >
        <HomeCta data={data} />
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Index {
    ...MainImage
    ...HomeQuote
    leftColumn: contentfulHomeColumn(position: { eq: "Left" }) {
      ...Column
    }
    rightColumn: contentfulHomeColumn(position: { eq: "Right" }) {
      ...Column
    }
    ...HomeCta
  }
`
