import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  overText: {
    fontSize: `calc((4.2 - 1) * 1.2vmax + 1rem)`,
    color: `white`,
    fontWeight: `normal`,
  },
  overCta: {
    composes: `$overText`,
    transition: `opacity 0.1s linear`,
    "&:hover": {
      opacity: 0.8,
    },
  },
})

interface Props {
  data: GatsbyTypes.HomeCtaFragment
}

const HomeCta = ({ data }: Props) => {
  const cta = data.contentfulHomeCta
  const classes = useStyles()

  return (
    <div
      style={{
        position: `relative`,
        height: `100%`,
        width: `100%`,
      }}
    >
      {cta != null && (
        <GatsbyImage
          style={{
            height: "100%",
            width: "100%",
            filter: `brightness(0.7) saturate(1.5)`,
          }}
          objectPosition="50% 70%"
          image={cta.image?.gatsbyImageData}
          alt=""
        />
      )}
      <div
        style={{
          position: `absolute`,
          height: `100%`,
          width: `100%`,
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            height: `100%`,
            width: `100%`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <a href={cta?.link} target="_blank" className={classes.overCta}>
            {cta?.title}
          </a>
        </div>
      </div>
    </div>
  )
}

export default HomeCta

export const HomeCtaFragment = graphql`
  fragment HomeCta on Query {
    contentfulHomeCta(cta: { eq: "CTA" }) {
      image {
        gatsbyImageData
      }
      title
      link
    }
  }
`
