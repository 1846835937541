import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

interface Props {
  data: GatsbyTypes.ColumnFragment
}

const ColumnItem = ({ data }: Props) => {
  const { blurb, header, image, link } = data
  return (
    <>
      <div className="column-image">
        {image?.gatsbyImageData != null && (
          <GatsbyImage
            image={image?.gatsbyImageData}
            style={{
              height: "100%",
              width: "100%",
              // filter: `brightness(0.7) saturate(1.5)`,
            }}
            // alt="A view of the mountains from Second Breakfast"
          />
        )}
      </div>
      <div
        style={{
          width: `66.667%`,
          margin: `auto`,
          padding: `17px 17px 34px`,
          boxSizing: `border-box`,
          textAlign: `center`,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        <h3
          style={{
            marginBottom: `2rem`,
            fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
          }}
        >
          {header}
        </h3>
        <p
          style={{
            margin: `1rem 0`,
            lineHeight: `1.6`,
            fontSize: `calc((1.3 - 1) * 1.2vw + 1rem)`,
          }}
        >
          {blurb}
        </p>
        {link != null && (
          <Link className="column-link" to={link}>
            Learn More
          </Link>
        )}
      </div>
    </>
  )
}

export default ColumnItem

export const column = graphql`
  fragment Column on ContentfulHomeColumn {
    blurb
    header
    image {
      gatsbyImageData(aspectRatio: 1.5, width: 1200, quality: 100)
    }
    link
  }
`
