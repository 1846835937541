import { graphql } from "gatsby"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  root: { width: `75%`, textAlign: `center`, paddingBottom: `4vw` },
  flavor: {
    fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
  },
})

interface Props {
  data: GatsbyTypes.HomeQuoteFragment
}

const HomeQuote = ({ data }: Props) => {
  const classes = useStyles()

  if (
    data.contentfulQuote?.quote?.quote == null ||
    data.contentfulQuote?.person == null
  ) {
    return null
  }

  return (
    <div className={classes.root}>
      <h3 className={classes.flavor}>
        “{data.contentfulQuote?.quote?.quote}”
        <br />- {data.contentfulQuote?.person}
      </h3>
    </div>
  )
}

export default HomeQuote

export const data = graphql`
  fragment HomeQuote on Query {
    contentfulQuote(pageId: { eq: "home" }) {
      person
      quote {
        quote
      }
    }
  }
`
